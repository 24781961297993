import { Box, Text } from '@chakra-ui/react';

import { Content, ContentBox } from '@/pages/common';

export const NoEnvironment = () => {
  return (
    <Content>
      <ContentBox px="16px" py="16px" flexGrow={1}>
        <Box py="8px" textAlign="center">
          <Text fontSize="lg" fontWeight="bold">
            サイドメニューから環境を選択してください。
          </Text>
        </Box>
      </ContentBox>
    </Content>
  );
};
